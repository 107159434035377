import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";
import { Link } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
  ${normalize}
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    overflow: auto;
    font-size: 62.5%;
  }
  body {
    position: relative;
    height: 100%;
    margin: 0;
    font-family: "source-han-sans-japanese", sans-serif;
    font-weight: 100;
    line-height: 1.8;
    color: #707070;
    word-wrap: break-word;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -webkit-font-kerning: normal;
    font-kerning: normal;
    font-size: 1.6rem;
    display: flex;
  }
  #root {
    width: 100%;
  }
`;

const Header = styled.header`
  width: 100%;
  border-bottom: 1px solid #ededed;
  padding: 18px;
  display: flex;
  justify-content: left;
  align-items: center;
`;

const Heading1 = styled.h1`
  margin: 0;
  font-size: 18px;
`;

const Heading2 = styled.h2`
  position: relative;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  padding-left: 20px;
  &::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 0;
    top: calc(50% + 2px);
    border-top: solid 1px;
    border-right: solid 1px;
    transform: rotate(45deg) translateY(-50%);
    color: inherit;
  }
`;

const Main = styled.main`
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  padding: 18px;
`;

const Footer = styled.footer`
  width: 100%;
  font-size: 12px;
  padding: 8px 18px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

type LayoutProps = {
  title: string;
};

export const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <>
      <GlobalStyle />
      <Header>
        <Heading1>
          <StyledLink to="/">rgba</StyledLink>
        </Heading1>
        {props.title && (
          <Heading2>
            <StyledLink to={`/${props.title}`}>{props.title}</StyledLink>
          </Heading2>
        )}
      </Header>
      <Main>
        <Container>{props.children}</Container>
      </Main>
      <Footer>©2020 rgba</Footer>
    </>
  );
};
