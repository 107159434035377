import * as React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../components/Layout";
import styled from "styled-components";
import ay6bv36xepn6 from "../assets/ay6bv36xepn6.gif";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #333;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledP = styled.p`
  margin: 0;
`;

export const Home: React.SFC<{}> = () => {
  return (
    <Layout title="">
      <StyledLink to="ay6bv36xepn6">
        <StyledP>
          <img src={ay6bv36xepn6} alt="ay6bv36xepn6" />
        </StyledP>
        <StyledP>ay6bv36xepn6</StyledP>
      </StyledLink>
    </Layout>
  );
};
