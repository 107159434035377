import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Layout } from "../components/Layout";
import { Button } from "../components/Button";
import { ReactComponent as ArrowBack } from "../assets/arrow_back-24px.svg";
import { ReactComponent as FiberManualRecord } from "../assets/fiber_manual_record-24px.svg";
import * as THREE from "three";
import { recordCanvas } from "../utils/recordCanvas";

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const StyledIconLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 18px;
  width: 34px;
  height: 34px;
  border: 1px solid #999;
  border-radius: 50%;
  svg {
    fill: #666;
  }
`;

export const Ay6bv36xepn6: React.FC<{}> = () => {
  let renderer: THREE.WebGLRenderer;
  let scene: THREE.Scene;
  let camera: THREE.Camera;
  let canvas: HTMLCanvasElement;
  let cubeArray: THREE.Mesh[] = [];

  function init(_canvas: HTMLCanvasElement) {
    if (!_canvas) {
      return;
    }
    canvas = _canvas;
    renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true });
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(50, 1200 / 580, 1, 10000);
    camera.position.z = 500;
    renderer.setSize(1200, 600);
    const directionalLight = new THREE.DirectionalLight(0xcccccc);
    directionalLight.position.set(1, 1, 10);
    scene.add(directionalLight);
    const geometry = new THREE.BoxGeometry(60, 60, 60);

    for (let i = 0; i < 30; i++) {
      for (let j = 0; j < 15; j++) {
        const material = new THREE.MeshLambertMaterial({ color: 0xff0000 });
        const cube = new THREE.Mesh(geometry, material);
        scene.add(cube);
        cube.position.set(i * 40 - 600, j * 40 - 300, 0);
        cubeArray.push(cube);
      }
    }
    animate();
  }

  function animate() {
    requestAnimationFrame(animate);
    for (let i = 0; i < cubeArray.length; i++) {
      const cube = cubeArray[i];
      cube.rotation.x += 0.001;
      cube.rotation.y += 0.002;
      cube.rotation.z += (i + 1) * 0.0001;
    }
    renderer.render(scene, camera);
  }

  const onCanvasLoaded = (canvas: HTMLCanvasElement) => {
    init(canvas);
  };

  const onCanvasRecord = (e) => {
    const svgElement = e.target as SVGAElement;
    svgElement.style.fill = "#666";
    recordCanvas(canvas);
  };

  return (
    <Layout title="ay6bv36xepn6">
      {process.env.NODE_ENV === "development" && (
        <Button onClick={onCanvasRecord}>
          <FiberManualRecord />
        </Button>
      )}
      <Canvas id="canvas" ref={onCanvasLoaded} />
      <StyledIconLink to="/">
        <ArrowBack />
      </StyledIconLink>
    </Layout>
  );
};
